<script>
import DiscoverCarousel from '../Carousel/DiscoverCarousel.vue';
import TabsComponent from '../Tabs/Tabs-component.vue';

export default{
    props: {
        carousel: {
            type: Object,
            required: true
        },
        description: String
    },

    components: {
        DiscoverCarousel,
        TabsComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    mounted() {
    },
}

</script>

<template>
    <section>
        <div class="bg-center py-5 bg-no-repeat bg-cover text-white first-page" :style="`background-image: url('${publicPath}assets/img/bg3.png')`">

            <!-- Title -->
            <div class="my-5 relative flex mx-12 lg:mx-96 py-12 items-center">
                <div class="flex-grow border-t border-1 border-blue-2"></div>
                    <h3 class="flex-shrink font-bold px-5 text-blue-2">{{ carousel.title }}</h3>
                <div class="flex-grow border-t border-1 border-blue-2"></div>
            </div>

            <!-- Carousel -->
            <TabsComponent v-if="carousel.tabs" :cards="carousel.tabs"/>

            <DiscoverCarousel v-if="carousel.cards" :cards="carousel.cards" />

            <!-- Button -->
            <!--<div class="flex justify-center mt-12">
                <button class="bg-white text-blue-4 font-bold py-3 px-10 rounded">Voir plus</button>
            </div>-->
        </div>
    </section>
</template>
<style>

.first-page p ul{
    list-style: circle;
    margin-left: 50px;
}
</style>