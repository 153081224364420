<script>
import DiscoverCarousel from '../Carousel/DiscoverCarousel.vue';

export default {

    data() {
        return {
            activeTabIndex: 0,
            cardsArray: []
        }
    },
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    methods: {
        setActiveTab(index) {
            this.activeTabIndex = index;
        }
    },
    components: {
        DiscoverCarousel
    }
}
</script>

<template>
<div class=" mb-14 grid w-full place-items-center">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center ml-5 md:ml-0" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
        <li class="me-2 mb-1 md:mb-0 bg-white text-blue-4 transition-all duration-100 ease-out" :class="{'active-tab-class': activeTabIndex === index}"  role="presentation" v-for="(titre, index) in cards" :key="index">
            <button @click="setActiveTab(index)" class="inline-block p-4 border-b-2 rounded-t-lg" id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> {{ titre.titre }}</button>
        </li>
    </ul>
</div>
<div id="default-styled-tab-content">

<DiscoverCarousel :cards="cards[activeTabIndex].cards" />

</div>

</template>

<style>

#default-styled-tab .active-tab-class{
    background: #61b9c2;
    color: white;
}

</style>