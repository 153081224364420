<script setup>
import HorizontalNavbar from '@/components/Navbars/HorizontalNavbar.vue';
import FooterPage from '@/components/Footer/Footer-page.vue';
import BullesComponent from './components/Bulles/Bulles-component.vue';
import CookiesComponent from './components/Cookies/Cookies-component.vue';
import '@/assets/css/main.css'
import './index.css'

</script>

<script>
export default {

  data: function() {
    return {
      showBanner: !(this.$posthog.has_opted_in_capturing() || this.$posthog.has_opted_in_capturing())
    }
  },

  computed: {
    isHomepage() {
      // Check if the current route is the homepage
      return this.$route.path === '/';
    }
  }
};
</script>

<template>
  <HorizontalNavbar />
  <RouterView />
  <BullesComponent v-if="!isHomepage" />
  <CookiesComponent v-if="showBanner"  @hideBanner="showBanner = false" />
  <FooterPage />
</template>

<style>

</style>
