<script>
import AsideNavbar from '@/components/Navbars/AsideNavbar.vue';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  props: {
    text: String,
    imageUrl: String,
    title: String
  },
  components: {
    AsideNavbar
  }
}

</script>

<template>

  <div class=" h-81 md:h-screen bg-no-repeat bg-cover" :style="`background: url('${imageUrl}')`">
    <AsideNavbar />
    <div class="h-full md:text-white text-blue-2 px-10 content-center md:w-60-percent mx-auto">
      <div class="pt-60 md:pt-0">
        <div class="font-bold uppercase 2xl:text-6xl text-2xl flex">
          <div>
            <span class="h-60 mb-10">{{ title }}</span>
            <div class=" bg-gradient-to-r from-blue-2 to-blue-4 p-1 w-32 mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>