<script>

export default {
  components: {},
  data() {
		return {
        headers: [],
        publicPath: process.env.BASE_URL
		};
	},
  methods : {
        getMenu(){
            try {

              // Make API call
              fetch('https://dev.cryopal.com/api/v1/menu', {
                 method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }).then((response) => response.json())
            .then((data) => {
              if(data.data){
                 this.headers = data.data;
              }
                
            })
            .catch((error) => {
              console.error('Unaunthenticated', error);
            });

          } catch (error) {
              console.error('Error fetching data:', error);
          }
        },
        gotoPageProduct(name){
              this.$router.push({ name: `pageInternePath`, params:{path: name } });
            }
  },

  mounted() {
  }
}
</script>
<template>

<footer class="bg-white rounded-lg shadow mt-100">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
            <a href="/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class="h-12" alt="Cryopan" />
            </a>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-blue-4 sm:mb-0">
                <li>
                    <a href="#" class="underline me-4 md:me-6">A propos</a>
                </li>
                <li>
                    <a href="/contactez-nous" class="underline me-4 md:me-6">Contactez-nous</a>
                </li>
                <li>
                    <a href="#" class="underline me-4 md:me-6">Téléchargements</a>
                </li>
            </ul>
        </div>
        </div>

    <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between mb-8">
      <span class="text-sm w-5/12 text-blue-2 font-bold">Cryopal, fondée en 1968, conçoit et fabrique des réservoirs de cryoconservation de qualité, innovants et orientés vers l'utilisateur, des lignes sous vide et des services pour le stockage cryogénique de matériaux biologiques. Cryopal fournit également une gamme de réservoirs d'oxygène liquide pour les patients souffrant de maladies respiratoires.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-blue-2 sm:mt-0">
        <li>
            <a @click="gotoPageProduct('plan-du-site')" class="hover:underline me-4 md:me-6 cursor-pointer">Plan du site</a>
        </li>
        <li>
            <a @click="gotoPageProduct('mention-legales')" class="hover:underline me-4 md:me-6 cursor-pointer">Mentions légales</a>
        </li>
        <li>
            <a @click="gotoPageProduct('politiques-de-cookies')" class="hover:underline me-4 md:me-6 cursor-pointer">Politique de cookies</a>
        </li>
        <li>
            <a @click="gotoPageProduct('gestion-de-cookies')" class="hover:underline cursor-pointer">Gestion de cookies</a>
        </li>
    </ul>
    </div>
    <div class=" w-full p-4 bg-blue-1">
    <span class="block text-sm text-blue-2 font-bold sm:text-center">Cryopal - Développé par AM Web Solutions LTD © 2024. Tous droits réservés </span>
    </div>
</footer>
</template>


