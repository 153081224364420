<script>
import LoginPage from '../Modal/LoginPage.vue';


export default {
  components: {
    LoginPage
  },
  data() {
    return {
      headers: [],
      showLogin: false,
      showSearch: false
    };
  },
  methods: {
    getMenu() {
      try {
        // Make API call
        fetch('https://dev.cryopal.com/api/v1/menu', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }).then((response) => response.json())
          .then((data) => {
            if (data.data) {
              this.headers = data.data;
            }

          })
          .catch((error) => {
            console.error('Unaunthenticated', error);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    login() {
      this.showLogin = true;
    },
    search() {
      if(this.showSearch == false){

        this.showSearch = true ;
      }
      else{
        this.showSearch = false;
      }
       
    },
    closeModalLogin() {
      this.showLogin = false;
    }
  },

  mounted() {

  }
}
</script>

<template>
  <nav class="bg-blue-1 text-blue-2">
    <div class="w-full px-8 2xl:px-28 flex flex-wrap items-center justify-end mx-auto p-4">
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0">
          <li class=" me-2">
            <a href="/qui-sommes-nous"
              class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold"
              aria-current="page">Qui sommes nous</a>
          </li>
          <li class="me-4">
            <a href="/maintenance-cryo"
              class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold"
              aria-current="page">Service Après Vente</a>
          </li>
          <li class=" flex items-center">
            <a href="#" class="block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0">
              <svg @click="search()" class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
              </svg>
            </a>
            <div class="relative ml-3 transition-all duration-500" v-if="this.showSearch">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              </div>
              <input type="search" id="default-search"
                class="block w-full p-1 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Recherche" required />
            </div>
          </li>
          <li>
            <a @click="login()" class="block cursor-pointer py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0">
              <svg class="w-6 h-6" aria-hidden="true" xmlns= "http://www.w3.org/2000/svg" width="24" height="24"
                fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                  d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#" class="block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0 font-bold">
              FR
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/cryopal" class="block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0" target="blank">
              <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                  d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                  clip-rule="evenodd" />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <LoginPage v-if="this.showLogin" @close="closeModalLogin()" />

</template>

<style>
.header {
  background-color: rgba(116, 143, 201, 0.5);
}
</style>