<script>
    export default {
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        }
    }
</script>
<template>
    <div class=" h-64 bg-white grid grid-cols-1 place-items-center">
        <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class=" h-16" alt="Cryopal Logo" /> 
        <h1 class=" text-2xl"> Page en construction </h1>
    </div>
</template>