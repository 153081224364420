<script>
export default {
        props:{
            text: String,
            contents: Array,
            text2: String
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
          }
        },
        methods: {
            getBackgroundColor(index) {
            const colors = ['bg-bgblue-1', 'bg-bgblue-2', 'bg-bgblue-3', 'bg-bgblue-4', 'bg-bgblue-5']; // Add more colors as needed
            return colors[index % colors.length];
            }
        },
        mounted() {
          this.publicPath = process.env.BASE_URL;
        }
}
</script>
<template>
    <section class=" h-auto w-full  grid place-items-center pb-20 page-interne" :style="`background-image: url('${publicPath}assets/img/contactForm_bg.png')`">
    <div class=" md:w-2/3 2xl:w-2/3 w-11/12">
        <div class="absolute w-2/3 2xl:w-2/3 grid place-items-center mt-9">
            <img class="h-20" :src="`${publicPath}assets/img/logobg_white.png`" />
        </div>
        <p class=" text-blue-2 mb-20 mt-20 font-bold text-left leading-10 bg-white p-8 md:p-20 shadow-md shadow-blue-4 rounded-md" v-html="text"></p>
        <p v-if="text2" class=" text-blue-2 mb-20 mt-20 font-bold text-center">« {{ text2 }} »</p>
    </div>
    <div v-for="(content, index) in contents" :key="index" :class="getBackgroundColor(index)" class="rounded-md w-90-percent 2xl:w-1/2 text-white mb-14 hover:shadow-blue-4 hover:shadow-lg">
        <div class="relative flex py-10 items-center p-8 2xl:p-12">
        <div class="flex-grow border-t border-2 border-blue-4"></div>
        <span class="flex-shrink mx-6 text-white font-bold">{{ content.title }}</span>
        <div class="flex-grow border-t border-2 border-blue-1"></div>
        </div>
        <div class=" px-12 pb-12">
            <ul class=" list-disc space-y-5">
                <li v-for="(value, index) in content.values" :key="index">{{ value }}</li>
            </ul>
            <img class=" mt-10" v-if="content.urlImage" :src="`${publicPath}${content.urlImage}`" />
            <p class=" text-xs" v-if="content.imageLegend" >{{ content.imageLegend }}</p>
        </div>
        <div class="absolute w-90-percent 2xl:w-1/2 grid place-items-center mt-40-neg">
            <img class="h-20" :src="`${publicPath}assets/img/logobg_white.png`" />
        </div>
        
    </div>

</section>
</template>

<style>
    .page-interne a{
        background-color: #4fbabc;
        color: white;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 6px;
        display: inline-block;
    }
    .page-interne a:hover{
        background: white;
        color: #4fbabc;
        border: 1px solid #4fbabc;
        transition: auto;
        animation-duration: revert;
    }

    .page-interne ul{
        list-style: circle;
        margin-left: 70px;
    }

    @media (max-width : 735px){
        .page-interne a {
            display: block;
            text-align: center;
        }
        .page-interne ul{
        list-style: circle;
        margin-left: 10px;
        }
    }
</style>