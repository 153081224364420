import posthog from "posthog-js";

export default {
  install(app) {
    posthog.init(
      "phc_OJ8bPHkbXhY2KJ8F81U8TCbQfy90wRR7LwNjkQw3Mgk",
      {
        api_host: "https://us.i.posthog.com",
        autocapture: true,
        capture_pageview: true,
      }
    );

        // Set initial capturing state based on user's choice
        const userOptedIn = localStorage.getItem('userOptedIn');
        if (userOptedIn === null) {
          // First time visit, set to false by default
          localStorage.setItem('userOptedIn', false);
          posthog.opt_out_capturing();
        } else if (userOptedIn === 'true') {
          posthog.opt_in_capturing();
        } else {
          posthog.opt_out_capturing();
        }
    
        app.config.globalProperties.$posthog = posthog;
  },
};