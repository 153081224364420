<script>
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            showInfo: false
        };
    },
    methods: {
        toggleMenu() {
            this.showInfo = !this.showInfo;
        },
    },
    computed: {
        backgroundStyle() {
        return {
            backgroundImage: `url(${this.publicPath}assets/img/photo-bulles-contact.jpg)`
        };
    }

    }
}
</script>

<template>

    <div>
        <div class="fixed top-3/4 md:right-20 right-4 cursor-pointer  bg-blue-3 md:p-5 p-1 rounded-full z-10">
            <img class="h-10" :src="`${publicPath}assets/img/logo_cr.png`" @click="toggleMenu" />
        </div>
    </div>
    <div v-show="showInfo" class=" ">
        <div id="authentication-modal" tabindex="-1" aria-hidden="true"
            class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 grid grid-cols-1 gap-2 place-items-center w-full md:inset-0  max-h-full bg-white bg-opacity-55 backdrop-blur-sm transition-opacity ease-in duration-300">
            <div class="relative p-4 w-full md:w-6/12 max-h-full">
                <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <!-- Modal header -->
                    <div class="flex items-center justify-between p-2 md:p-2 rounded-t dark:border-gray-600">
                        <button @click="toggleMenu" type="button"
                            class="end-2.5 text-blue-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="authentication-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="p-4 md:p-5">
                        <div class="grid md:grid-cols-2 p-6 rounded-2xl h-full row-span-2">
                            <div
                                class="items-center justify-center p-5 text-center h-96 bg-cover bg-center" :style="backgroundStyle">
                            </div>
                            <div class="items-center justify-center p-5 text-center bg-opacity-00">
                                <blockquote class="max-w-2xl mx-auto mb-4 text-blue-2-500 lg:mb-8">
                                    <h1 class="text-blue-2 text-4xl">CONTACTEZ-NOUS DES <span class=" text-blue-4">AUJOURD'HUI</span></h1>
                                </blockquote>
                                <figcaption class="mx-auto ml-5 mt-24">
                                    <!--<p class=" text-left flex mb-5 ">
                                        <img
                                            :src="`${publicPath}assets/img/phone.png`" class=" h-9 mr-3" />
                                        <a href="tel:0164761500" class="text-blue-2 font-bold text-xl"> 01 64 76 15 00 </a> 
                                        
                                    </p>-->
                                    <p class=" flex text-xl">
                                        <img
                                            :src="`${publicPath}assets/img/formulaire.png`" class=" h-9 mr-3" />
                                        <a href="/contactez-nous" class="text-blue-2 font-bold text-xl">Formulaire de Contact</a> 
                                    </p>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  
</template>