<script>
import BlueCarousel from '../Carousel/BlueCarousel.vue';
export default{

    components: {
        BlueCarousel,
    },
    props: {
        carousel: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    mounted() {
    },
}

</script>

<template>
    <section>
        <div class="bg-center py-10 bg-no-repeat bg-cover" :style="`background-image: url('${publicPath}assets/img/bg3.png')`">
            <!-- Title -->
            <div class="my-10 relative flex mx-12 lg:mx-96 items-center">
                <div class="flex-grow border-t border-2 border-blue-2"></div>
                    <h3 class="flex-shrink font-bold px-5">{{ carousel.title }}</h3>
                <div class="flex-grow border-t border-2 border-blue-3"></div>
            </div>

            <!-- Carousel -->
            <BlueCarousel v-if="carousel.cards" :cards="carousel.cards" />

            <!-- Button -->
            <!--<div class="flex justify-center mt-12">
                <button class="bg-white text-blue-4 font-bold py-3 px-10 rounded">Voir plus</button>
            </div>-->
        </div>
    </section>
</template>