import { createWebHistory, createRouter } from "vue-router"
import GeneratePage from '@/components/Pages/GeneratePage.vue'
import GenerateInternPage from '@/components/Pages/GenerateInternPage.vue'


const routes = [
  {
    path: "",
    name: "HomePage",
    component: () => import("@/components/Pages/HomePage.vue")
  },
  {
    path: "/contactez-nous",
    name: "Contact",
    component: () => import("@/components/Pages/ContactPage.vue"),
  },
  {
    path: "/:category/:page",
    children: [
      {
        path: "",
        name: "page",
        component: GeneratePage,
      },
      {
        path: ":pageinterne",
        name:"pageInterne",
        component: GenerateInternPage
      }
      
    ]
  },
  {
    path: "/:path",
    children: [
      {
        path: "",
        name:"pageInternePath",
        component: GenerateInternPage
      }
      
    ]
  },
  {
    path: "/:path",
    children: [
      {
        path: "",
        name:"pageProductPath",
        component: GeneratePage
      }
      
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router