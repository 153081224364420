<script>
    export default {
        data() {
            return {
                publicPath: process.env.BASE_URL,
                openMenu: false,
                openSubMenu: null
            };
        },
        methods: {
            toggleMenu() {
                this.openMenu = !this.openMenu;
            },
            toggleSubMenu(menu){
              this.openSubMenu = this.openSubMenu === menu ? null : menu;
            },
            gotoPage(category, page){
              this.$router.push({ path: `/${category}/${page}` });
              this.openMenu = false;
            },
            gotoPageProduct(name){
              this.$router.push({ name: `pageInternePath`, params:{path: name } });
              this.openMenu = false;
            }
        },

        mounted() {
            this.publicPath = process.env.BASE_URL;
        },

        computed: {
            background() {
                return this.openMenu ? 'bg-white' : '';
            }
        }
    };
    </script>

<template>


<nav class=" absolute w-full z-10">
  <div :class="`w-full px-8 2xl:px-28 flex flex-wrap items-center justify-between mx-auto p-4 relative ${ background }`">
    <div v-if="openSubMenu && openMenu" class="absolute hidden lg:block text-blue-1 left-1/3 bottom-4 z-1000 text-9xl font-bold place-items-end text-center">
      {{ openSubMenu }}
    </div>
    <RouterLink to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class=" h-16" alt="Flowbite Logo" />
    </RouterLink>
    <div class=" flex">
    <a href="#" class="text-blue-2 block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0 lg:hidden">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clip-rule="evenodd"/>
              <path d="M7.2 8.809H4V19.5h3.2V8.809Z"/>
            </svg>
    </a>
    <button @click="toggleMenu" data-collapse-toggle="navbar-hamburger" type="button" class="inline-flex items-center justify-center p-2 w-10 h-10 text-sm rounded-lg focus:outline-none ring-2 ring-white focus:ring-blue-4 bg-gradient-to-tr from-blue-2 to-blue-4" aria-controls="navbar-hamburger" aria-expanded="false">
      <span class="sr-only">Open main menu</span>
      <svg class="w-5 h-5 text-white focus:text-blue-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 1h15M1 7h15M1 13h15"/>
      </svg>
    </button>
  </div>
    <div :class="['w-full',  { 'hidden': !openMenu }]" id="navbar-hamburger">
      <ul class="flex flex-col font-medium mt-4 rounded-lg text-blue-2">
        <li class="hover:text-blue-4 lg:hidden">
          <a href="#" class="block py-2 px-3 rounded ">Connexion client</a>
        </li>
        <li class="hover:text-blue-4 lg:hidden">
          <a href="/qui-sommes-nous" class="block py-2 px-3 rounded ">Qui sommes nous</a>
        </li>
        <li class="hover:text-blue-4 lg:hidden">
          <a href="/maintenance-cryo" class="block py-2 px-3 rounded ">Service Après Vente</a>
        </li>
        <!-- Vos besoins -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2">
          <div>
            <a class="py-2 px-3 rounded hover:text-blue-4 text-center inline-flex items-center cursor-pointer" @click="toggleSubMenu('Vos besoins')">
              Vos besoins
              <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
          <div id="dropdownNavbar"  v-show="openSubMenu === 'Vos besoins'" class="col-start-2 z-1  font-normal divide-y w-full text-blue-4">
                <ul class="py-2 text-sm list-disc  font-semibold ml-6 lg:grid lg:grid-flow-col grid-rows-3" aria-labelledby="dropdownLargeButton">
                  <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('vos-besoins', 'conserver-transporter-des-echantillons-dans-lazote-liquide-ou-gazeux')" class="block pr-12 py-1 cursor-pointer">Conserver des échantillons dans l'azote liquide ou gazeux</a>
                  </li>
                  <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('vos-besoins', 'stocker-transporter-utiliser-de-loxygene-liquide-pour-loxygenotherapie')" class="block pr-12 py-1 cursor-pointer">
                      Transporter des échantillons à température cryogénique
                    </a>
                  </li>
                  <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('vos-besoins', 'stocker-transporter-transferer-utiliser-de-lazote-liquide')" class="block pr-12 py-1 cursor-pointer">
                      Stocker, transferer des fluides cryogéniques
                    </a>
                  </li>
                  <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('vos-besoins', 'concevoir-une-salle-de-cryoconservation')" class="block pr-12 py-1 cursor-pointer">
                      Concevoir une salle de cryoconservation
                    </a>
                  </li>
                  <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('vos-besoins', 'utiliser-de-l-oxygene-liquide-pour-l-oxygenotherapie')" class="block pr-12 py-1 cursor-pointer">
                      Utiliser de l'oxygène liquide pour l'oxygenothérapie
                    </a>
                  </li>
                </ul>
            </div>
        </li>

        <!-- Nos produits -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2">
          <div>
            <a class="inline-flex items-center justify-center py-2 px-3 rounded hover:text-blue-4 cursor-pointer" @click="toggleSubMenu('Nos produits')">
              Nos produits
              <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
          <div id="dropdownNavbar"  v-show="openSubMenu === 'Nos produits'" class="z-1  font-normal bg-white divide-y w-full text-blue-4">
            <ul class="py-2 text-sm list-disc  font-semibold lg:grid lg:grid-flow-col grid-rows-3 gap-x-1.5 ml-6" aria-labelledby="dropdownLargeButton">
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPageProduct('page-produits')" class="block pr-12 py-1 cursor-pointer">VOIR NOS PRODUITS</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPageProduct('agil')" class="block pr-12 py-1 cursor-pointer">AGIL</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPageProduct('arpege')" class="block pr-12 py-1 cursor-pointer">ARPEGE</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPageProduct('espace')" class="block pr-12  py-1 cursor-pointer">ESPACE</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPageProduct('flexible')" class="block pr-12 py-1 hover:text-blue-4 cursor-pointer">FLEXIBLES</a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Nos marchés -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2">
          <div>
            <a class="inline-flex items-center justify-center py-2 px-3 rounded  hover:text-blue-4 cursor-pointer" @click="toggleSubMenu('Nos marchés')">
              Nos marchés
              <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
          <div id="dropdownNavbar"  v-show="openSubMenu === 'Nos marchés'" class="z-1  font-normal bg-white divide-y w-full text-blue-4">
            <ul class="py-2 text-sm list-disc  font-semibold lg:grid lg:grid-flow-col grid-rows-3 ml-6" aria-labelledby="dropdownLargeButton">
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Biobanking</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Biologie de la reproduction (FIV - PMA - CECOS)</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Santé animale - Insémination animal</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Unités de Thérapie cellulaire</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Logistique sous températures contrôlées</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">R&D</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Recherche fondamentale</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Dermatologie</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('markets', 'discover')" class="block pr-12 py-1 cursor-pointer">Oxygénothérapie</a>
              </li>
            </ul>
          
          </div>
        </li>

        <!-- Nos services  -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2">
          <div>
            <a class="inline-flex items-center justify-center py-2 px-3 rounded hover:text-blue-4 cursor-pointer" @click="toggleSubMenu('Nos services')">
              Nos services
              <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
          <div id="dropdownNavbar"  v-show="openSubMenu === 'Nos services'" class="z-1  font-normal bg-white divide-y w-full text-blue-4">
            <ul class="py-2 text-sm list-disc  font-semibold lg:grid lg:grid-flow-col grid-rows-3 ml-6" aria-labelledby="dropdownLargeButton">
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('services', 'cryobiologie')" class="block pr-12 py-1 cursor-pointer">Nos services en cryobiologie</a>
              </li>
              <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('services', 'oxygenotherapie')" class="block pr-12 py-1 cursor-pointer">Nos services en oxygénothérapie</a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Téléchargement -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2">
          <div>
            <a class="inline-flex items-center justify-center py-2 px-3 rounded hover:text-blue-4 cursor-pointer" @click="toggleSubMenu('Téléchargement')">
              Téléchargement
            </a>
          </div>
            <div id="dropdownNavbar"  v-show="openSubMenu === 'Téléchargement'" class="z-1  font-normal bg-white divide-y w-full text-blue-4">
              <ul class="py-2 text-sm list-disc  font-semibold lg:grid lg:grid-flow-col grid-rows-3 ml-6" aria-labelledby="dropdownLargeButton">
                <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('downloads', 'cryo')" class="block pr-12 py-1 cursor-pointer">Téléchargement Cryo</a>
                </li>
                <li class=" hover:text-blue-2 transition-all duration-150">
                    <a @click="gotoPage('downloads', 'oxy')" class="block pr-12 py-1 cursor-pointer">Téléchargement OXY</a>
                </li>
              </ul>
            </div>
        </li>

        <!--  FAQ -->
         <li class=" hover:text-blue-2 transition-all duration-150">
          <a @click="gotoPageProduct('faq')" class="block py-2 px-3 rounded cursor-pointer ">FAQ</a>
        </li>
        <li class=" hover:text-blue-2 transition-all duration-150 lg:hidden">
          <a href="#" class="block py-2 px-3 rounded ">Recherche</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
    
</template>


<style>
  .watermark {
    opacity: 0.25;
    font-size: 3em;
      z-index: 1000;
  }
</style>