<script>
export default{
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    props: {
        title: String,
        text: String,
        imageUrl: String,
    }
}

</script>

<template>
<div class="flex rounded text-white p-8 bg-no-repeat bg-cover pt-60-percent shadow-lg shadow-blue-2 w-auto m-2 transition duration-300 ease-in-out hover:scale-110" :style="`background-image: url('${imageUrl}')`">
  <div class="p-0 h-full content-end">
    <div class="p-3 bg-white rounded-full w-14 my-2">
      <img :src="`${publicPath}assets/img/logo_cr.png`">
    </div>
    <ul class="flex flex-col gap-4">
      <li class="flex items-center gap-4">
        <p class="block font-sans antialiased font-normal leading-relaxed text-inherit text-sm">
            {{ title }}
        </p>
      </li>
      <li class="flex items-center gap-4 border-l-2 ps-2">
        <p class="block font-sans antialiased font-normal leading-relaxed text-inherit text-xs">
            {{ text }}
        </p>
      </li>
      <li class="flex items-center gap-4">
        <a href='#' class="block font-sans antialiased leading-relaxed text-inherit text-blue-4 font-bold">
            <img :src="`${publicPath}assets/img/fleche_right.png`">
        </a>
        <a href='#' class="block font-sans antialiased leading-relaxed text-inherit text-blue-4 font-bold">
             <span>En savoir plus</span>
        </a>
      </li>
    </ul>
  </div>
</div> 

</template>