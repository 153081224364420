<script>
import BannerPage from '@/components/Banners/BannerPage.vue';
import CardFormation from '../Card/Card-formation.vue';
import pagesInterne from '@/data/pagesInterne.json';
import SeeOtherSection from '../Sections/SeeOtherSection.vue';
import TabsSection from '../Sections/TabsSection.vue';


export default{

    components: {
        BannerPage,
        CardFormation,
        SeeOtherSection,
        TabsSection
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            content: null
        };
    },
    created() {
        this.getPageContent();
    },
    updated() {
        this.getPageContent();
    },
    methods: {
        // Function to get the page content
        getPageContent() {
            let category = this.$route.params.path;
            this.content = pagesInterne[category];
            this.content.description;
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    mounted() {
        this.scrollToTop();
    }
}

</script>

<template>
    
    <BannerPage v-if="content"  :title="content.title"  :imageUrl="`${publicPath}assets/img/formation-bg.png`"/>
    <CardFormation v-if="content.description" :text="content.description" :text2="content.description2" :contents="content.details" />
    
    <div v-if="content.images" class=" w-full 2xl:w-1/2 m-auto mb-12  ">
        <div  v-for="(image, index) in content.images" :key="index">
            <img v-if="image.url" :src="`${publicPath}${image.url}`" />
            <p class=" text-xs">{{ image.legend }}</p>
        </div>
    </div>

    <TabsSection v-if="content.carousel_1" :carousel="content.carousel_1"/>


    <!-- See Other Section -->
    <SeeOtherSection v-if="content.carousel_2" :carousel="content.carousel_2"/>
        
</template>