<script>
import BannerPage from '@/components/Banners/BannerPage.vue';
import pages from '@/data/pages.json';
import DiscoverSection from '../Sections/DiscoverSection.vue';
import TabsSection from '../Sections/TabsSection.vue';
import SeeOtherSection from '../Sections/SeeOtherSection.vue';
import PageConstruction from '../Sections/PageConstruction.vue';

export default{
    components: {
        BannerPage,
        DiscoverSection,
        SeeOtherSection,
        PageConstruction,
        TabsSection
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            content: null,
        };
    },
    created() {
        this.getPageContent();
    },
    updated() {
        this.getPageContent();
    },
    methods: {
        // Function to get the page content
        getPageContent() {
            let category = this.$route.params.category;
            let page = this.$route.params.page;
            this.content = pages[category][page];
        },

        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    mounted() {
        this.scrollToTop();
    }
}

</script>

<template>
    <!-- Banner -->
    <BannerPage v-if="content" :title="content.title"  :imageUrl="`${publicPath}assets/img/formation-bg.png`"/>

    <!-- Discover Section -->
    <DiscoverSection v-if="content.carousel_1" :carousel="content.carousel_1" :description="content.description"/>

    <TabsSection v-if="content.carousel_1" :carousel="content.carousel_1" :description="content.description" />

    <PageConstruction v-if="content.discover" />

    <!-- See Other Section -->
    <SeeOtherSection v-if="content.carousel_2" :carousel="content.carousel_2"/>
</template>