<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BlueCard from '../Card/BlueCard.vue';

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            swiperOptions: {
            slidesPerView: 3,
            spaceBetween: 'auto',
            breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            770: {
                slidesPerView: 2,
                spaceBetween: 50
            },

            771: {
                slidesPerView: 3,
                spaceBetween: 100
            }
            }
        }
        };
    },
    components: {
        BlueCard,
        Swiper,
        SwiperSlide
    },
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    setup() {
        // eslint-disable-next-line no-unused-vars
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation,Pagination]
        };
    }
};
</script>

<template>
    <div class="lg:mx-48 mx-auto">
        <Swiper 
        :modules="modules" 
        :slides-per-view="swiperOptions.slidesPerView" 
        :space-between="swiperOptions.spaceBetween" 
        :pagination="{ clickable: true }" 
        :breakpoints="swiperOptions.breakpoints"
        :loop="true"
        navigation
        @swiper="onSwiper"
        @slideChange="onSlideChange">
            <swiper-slide v-for="(card, index) in cards" :key="index">
                <BlueCard :title="card.title" text="Description" :imageUrl="`${publicPath}assets/img/slider2.png`" />
            </swiper-slide>
            ...
        </Swiper>
    </div>
</template>

<style scoped>
.swiper {
    padding-bottom: 5% !important;
}

.swiper-button-next,.ml-16 .swiper-button-prev {
    color: #1CBDBD !important;
    border: 1px solid #1CBDBD !important;
    padding: 0 20px 0 20px;
    border-radius: 50%;
}


.swiper-pagination-bullet {
  background-color: #31B7BC !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #31B7BC !important;
  width: 0.8rem !important;
  height: 0.8rem !important;
  opacity: 1 !important;
}

.swiper.swiper-initialized{
    place-content: center;
    display: grid;
}

.swiper-slide:last-child{
    margin-right: 0px !important;
}

@media (max-width: 765px) {
  .swiper {
  padding-bottom: 16% !important;
}
.swiper-backface-hidden .swiper-slide {
    width: 100% !important;
}
.swiper-slide:last-child{
    margin-right: 10px !important;
}

}
</style>