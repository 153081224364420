<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            swiperOptions: {
            slidesPerView: 3,
            spaceBetween: 'auto',
            breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            770: {
                slidesPerView: 2,
                spaceBetween: 50
            },

            771: {
                slidesPerView: 3,
                spaceBetween: 110
            }
            }
        }
        };
    },
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        gotoPage(name){
              this.$router.push({ name: `pageInternePath`, params:{path: name } });
        }
    },
    setup() {
        // eslint-disable-next-line no-unused-vars
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination]
        };
    }
};
</script>

<template>
    <div class="items-center">
    <div class="h-50 lg:w-5/6 mx-auto discover-carousel">
        <Swiper 
        :modules="modules" 
        :slides-per-view="swiperOptions.slidesPerView" 
        :space-between="swiperOptions.spaceBetween" 
        :pagination="{ clickable: true }" 
        :breakpoints="swiperOptions.breakpoints"
        :loop="true"
        navigation
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        >
            <swiper-slide v-for="(card, index) in cards" :key="index">
                <div class="text-center text-white transition duration-300 ease-in-out hover:scale-110">
                    <a @click="gotoPage(card.name)"><img :src="`${publicPath}assets/img/${card.image}`" alt="product" class="w-3/4 mx-auto h-64 object-cover rounded-md"></a>
                    <a href=""><h3 class="text-lg font-semibold mt-2 text-blue-2">{{ card.title }}</h3></a>
                    <div class=" absolute w-full h-64 top-0 left-0 bg-white opacity-0 z-10 transition-opacity duration-300 hover:opacity-100 grid place-items-center text-blue-2 ">
                        <p v-if="card.echantillon" class=" mt-10 font-bold"> {{ card.echantillon }}</p>
                        <p v-if="card.stockage " class="font-bold">Version stockage : {{ card.stockage }}</p>
                        <button class=" bg-blue-4 text-white p-3 rounded-sm" @click="gotoPage(card.name)">En savoir plus</button>
                    </div>
                </div>
            </swiper-slide>
        </Swiper>
    </div>
</div>
</template>
<style>
.swiper {
  padding-bottom: 5% !important;
  padding-left: 7%;
    padding-right: 7%;
}

.discover-carousel .swiper-pagination-bullet {
  background-color: #73b6c2 !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
  opacity: 1 !important;
}

.discover-carousel .swiper-pagination-bullet-active {
  background-color: #73b6c2 !important;
  width: 0.8rem !important;
  height: 0.8rem !important;
  opacity: 1 !important;
}

.discover-carousel .swiper-button-next, .discover-carousel .swiper-button-prev {
  color: #73b6c2 !important;
  border: 1px solid #73b6c2 !important;
  padding: 0 20px 0 20px;
  border-radius: 50%;
}

.swiper-button-next {
  right: 0;
}

@media (max-width: 765px) {
  .swiper {
    padding-left: 1%;
    padding-right: 1%;
  padding-bottom: 16% !important;
}
}
</style>