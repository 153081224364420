<script>

export default{
    props: {
        carousel: {
            type: Object,
            required: true
        },
        description: String
    },

    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    mounted() {
    },
}

</script>

<template>
    <section>
        <div v-if="description" class="bg-center py-5 bg-no-repeat bg-cover text-white first-page" :style="`background-image: url('${publicPath}assets/img/bg2.png')`">
            <!--Paragraph -->
            <div class="absolute w-2/3 2xl:w-full grid place-items-center mt-9">
                            <img class="h-20" :src="`${publicPath}assets/img/logobg_white.png`" />
                </div>
            <div class=" mx-4 lg:mx-56">
                <p v-if="description" class="text-blue-2 mb-20 mt-20 font-bold text-left leading-10 bg-white p-8 md:p-20 shadow-md shadow-blue-4 rounded-md" v-html="description">
                </p>
            </div>

            <!-- Button -->
            <!--<div class="flex justify-center mt-12">
                <button class="bg-white text-blue-4 font-bold py-3 px-10 rounded">Voir plus</button>
            </div>-->
        </div>
    </section>
</template>
<style>

.first-page p ul{
    list-style: circle;
    margin-left: 50px;
}
</style>